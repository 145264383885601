const React = require('react');
const { shape, string, bool } = require('prop-types');

const Card = require('@andes/card');

const { CardContent, CardFooter } = Card;
const classnames = require('classnames');
const Image = require('../../image');

const namespace = 'card-grid';

const CardGrid = ({ item, className, shadow, preload }) => (
  <Card
    className={classnames(className, `${namespace}-item`)}
    shadow={shadow}
    paddingSize={8}
  >
    <CardContent className={classnames(`${namespace}-container-image`)}>
      <Image src={item?.picture?.src} alt={`${item.title}`} preload={preload} ariaHidden="true" />
    </CardContent>
    <CardFooter className={classnames(`${namespace}-footer`)}>
      <p className={classnames(

        `${namespace}-item__title`,
      )}
      >
        <a
          className={classnames(
            `${namespace}-anchor`,

          )}
          href={item.target || item.permalink}
        >
          {item.title}
        </a>
      </p>
    </CardFooter>
  </Card>
);

CardGrid.propTypes = {
  item: shape({
    target: string,
    permalink: string,
    picture: shape({ src: string }),
  }),
  className: string,
  shadow: string,
  preload: bool,
};

module.exports = CardGrid;

const React = require('react');
const { string, shape, arrayOf } = require('prop-types');
const CarouselSnapped = require('@andes/carousel-snapped');
const injectI18n = require('nordic/i18n/injectI18n');

const Action = require('../../../commons/action');
const Section = require('../../../commons/section');
const CardGrid = require('../../../commons/card/card-grid');
const divideByGroupsOf = require('../divide-groups');
const withTracker = require('../../../commons/with-tracker');

const { CarouselSnappedSlide } = CarouselSnapped;

const categoriesByColumn = 3;
const categoriesByRow = 4;

// Assigning these propTypes to a variable let us add an `isRequired` on each use so eslint understands what we mean.
const elementsPropType = arrayOf(
  shape({
    target: string.isRequired,
    title: string.isRequired,
  }),
);

function CategoriesColumn({ elements }) {
  const missingBlocks = categoriesByColumn - elements.length;
  const fillerElements = Array.from({ length: missingBlocks }, (_, index) => (
    <div key={index} className="filler" />
  ));
  return (
    <div className="category-column">
      {elements.map(item => <CardGrid {...{ item }} key={item.id} />)}
      {fillerElements}
    </div>
  );
}

CategoriesColumn.propTypes = {
  elements: elementsPropType.isRequired,
};

function CategoriesDesktop({ title, elements, type, view_more_action }) {
  const items = divideByGroupsOf(elements, categoriesByColumn);
  return (
    <Section type={type}>
      <div className="row container">
        <div className="wrapper">
          <div className="section-header">
            <h2>{title}</h2>
            <Action {...view_more_action} id={null} />
          </div>
          <CarouselSnapped
            slidesPerView={categoriesByRow}
            spacing={20}
            pagination={{ position: 'top', mode: 'light' }}
          >
            {items.map(item => (
              <CarouselSnappedSlide key={item.id}>
                <CategoriesColumn {...item} />
              </CarouselSnappedSlide>
            ))}
          </CarouselSnapped>
        </div>
      </div>
    </Section>
  );
}

CategoriesDesktop.propTypes = {
  elements: elementsPropType.isRequired,
  title: string.isRequired,
  type: string.isRequired,
  view_more_action: shape(Action.propTypes),
};

module.exports = injectI18n(withTracker(CategoriesDesktop));
